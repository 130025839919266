import styled, { css } from "styled-components";

export const Container = styled.div``;

export const main = styled.main`
  ${({ theme: { space } }) => css`
    background-image: url("/assets/imgs/mainBg.svg");
    background-position: center;
    background-size: auto;
    width: 100%;,
    max-width: ${space.medio};
    background-repeat: no-repeat;
    margin: -180px auto 0;
    height: 1024px;
    padding-top: 386px;
    background-color: #1C1C1C;

    @media (max-width: 500px) {
      background-color: #1c1c1c;
      background-image: url("/assets/imgs/mainBg-mobile.svg");
      background-size: cover;
      background-position: top right;
      height: 400px;

      margin-top: -80px;
      padding: 100px 20px;
    }
  `}
`;

export const MainContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;

    > h1 {
      color: #e42e30;
      font-family: Roboto;
      font-size: 72px;
      font-style: normal;
      font-weight: 900;
      line-height: 80px;
      text-transform: uppercase;
      margin-bottom: 50px;

      span {
        color: #fff;
      }
    }

    > p {
      max-width: 586px;
      color: #fff;
      font-family: "Roboto";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 36px;

      span {
        color: #e42e30;
        font-weight: 700;
      }
    }

    button {
      margin-top: 80px;
    }

    @media (max-width: 500px) {
      position: relative;

      > h1 {
        font-size: 20px;
        margin-bottom: 16px;
      }

      > p {
        font-size: 16px;
        line-height: 24px;
        max-width: 261px;
      }

      > :nth-child(3) {
        display: none;
      }

      button {
        /* position: relative;
        top: 10px; */
        margin: 0 auto 0;
      }
    }
  `}
`;

export const About = styled.div`
  ${({ theme: { space } }) => css`
    /* max-width: ${space.large}; */
    width: 100%;
    margin: 0 auto;
    background-color: #1c1c1c;
    padding: 120px 0;

    > a button {
      margin: 120px auto 0;
    }

    @media (max-width: 500px) {
      padding: 24px 20px;

      > a button {
        margin: 40px auto;
      }
    }
  `}
`;

export const AboutContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;

    display: flex;
    gap: 212px;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column;
      gap: 24px 0;
    }
  `}
`;

export const AboutLeft = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: 22px;
    height: 212px;
    border-radius: 100px;
    border: 1px solid #e42e30;
    background-color: #e42e30;
    margin-right: 30px;
  }

  > h2 {
    color: #fff;
    font-family: "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 58px;
    text-transform: uppercase;
    max-width: 300px;

    > span {
      color: #e42e30;
    }
  }

  @media (max-width: 500px) {
    &::before {
      display: none;
    }

    > h2 {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin-bottom: 24px;
    }
  }
`;

export const AboutRight = styled.div`
  > div + div {
    margin-top: 80px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 0 35px;

    > p {
      color: #f7f7f7;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 160% */
      text-transform: uppercase;

      span {
        color: #e42e30;
      }
    }
  }

  @media (max-width: 500px) {
    > div + div {
      margin-top: 24px;
    }

    > div {
      gap: 0 22px;

      > img {
        display: block;
        width: 40px;
      }

      > p {
        font-size: 16px;
        line-height: 24px;
        max-width: 222px;
      }
    }
  }
`;

export const Services = styled.section`
  ${({ theme: { space } }) => css`
    padding: 120px 0;
    /* max-width: ${space.large}; */
    width: 100%;
    margin: 0 auto;
    background-color: #e42e30;

    @media (max-width: 500px) {
      padding: 32px 20px;
    }
  `}
`;

export const ServicesContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;
    display: grid;
    gap: 60px 50px;
    align-items: center;
    grid-template-columns: 6fr 6fr;
    grid-template-areas:
      "text price"
      "button  black";

    > :nth-child(1) {
      grid-area: text;
      position: relative;
      top: 40px;
    }

    > :nth-child(2) {
      grid-area: button;

      > button {
        margin: 0 auto;
      }
    }

    > :nth-child(3) {
      grid-area: price;
    }

    > :nth-child(4) {
      grid-area: black;
    }

    @media (max-width: 500px) {
      gap: 40px 0;

      grid-template-columns: 1fr;
      justify-content: center;

      grid-template-areas:
        "text"
        "price"
        "black"
        "button";
    }
  `}
`;

export const ServiceLeftSide = styled.div`
  width: fit-content;

  > p + p {
    margin-top: 40px;
  }

  > p {
    color: #fff;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    max-width: 514px;

    > span {
      color: #1c1c1c;
      font-weight: 700;
    }
  }

  > h2 {
    color: #1c1c1c;
    font-family: "Roboto";
    font-size: 72px;
    font-style: normal;
    font-weight: 900;
    line-height: 80px;
    max-width: 365px;
    text-transform: uppercase;
    margin-bottom: 40px;

    > span {
      color: #fff;
    }
  }

  button {
    margin: 80px auto 0;
  }

  @media (max-width: 500px) {
    margin: 0 auto;
    margin-bottom: 40px;

    h2 {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin-bottom: 24px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      max-width: 300px;
    }

    > :nth-child(3) {
      display: none;
    }
  }
`;

export const ServiceRightSide = styled.div`
  > div {
    > div:not(:last-child) {
      border-top: 2px solid #111;
      border-left: 2px solid #111;
      border-right: 2px solid #111;
    }
  }

  @media (max-width: 500px) {
    margin: 0 auto;
  }
`;

export const CardPrice = styled.div`
  & + div {
    margin-top: 43px;
  }

  width: 600px;
  min-height: 150px;

  .text {
    padding: 20px 0;

    > h4 {
      color: #fff;
      text-align: center;
      font-family: "Roboto";
      font-size: 20px;
      font-style: normal;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 32px;
      max-width: 560px;
      margin: 0 auto;

      span {
        display: inline-block;
        position: relative;
        top: -5px;
        margin-left: 5px;
        color: #111;
      }
    }
  }

  .text:first-child {
    border-top-right-radius: 60px;
    padding: 26px 0;
  }

  .black {
    background: #111;
    padding: 20px 0;
    border-bottom-left-radius: 60px;

    > h3 {
      color: #e42e30;
      font-family: "Roboto";
      font-size: 48px;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;

      display: flex;
      justify-content: center;
      align-items: start;
      gap: 0 6px;
    }
  }

  @media (max-width: 500px) {
    width: 312px;

    .text {
      padding: 16px 0;
    }

    .black {
      background: #111;
      padding: 20px 0;
      border-bottom-left-radius: 60px;

      > h3 {
        font-size: 32px;
        line-height: 48px;
      }
    }
  }
`;

export const BlackCard = styled.div`
  & + div {
    margin-top: 20px;
  }

  padding: 10px 60px;
  background-color: #111;
  height: 116px;
  display: flex;
  align-items: center;

  > p {
    color: #feffff;
    text-align: center;
    font-family: "Roboto";
    font-size: 21px;
    font-style: normal;
    line-height: 1.4;

    span {
      color: #e42e30;
      font-weight: bold;
    }

    img {
      position: relative;
      margin-right: 5px;
      top: -5px;
    }
  }

  @media (max-width: 500px) {
    padding: 10px;
    > p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const Localizations = styled.section`
  ${({ theme: { space } }) => css`
    width: 100%;
    margin: 0 auto;
    background-color: #111;
    padding: 120px 0;

    @media (max-width: 500px) {
      padding: 32px 20px;
    }
  `}
`;

export const LocalizationContent = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.medio};
    margin: 0 auto;

    h2 {
      color: #e42e30;
      font-family: "Roboto";
      font-size: 72px;
      font-style: normal;
      text-align: center;
      font-weight: 900;
      line-height: 80px;
      text-transform: uppercase;
      margin-bottom: 80px;

      span {
        color: #fff;
      }
    }

    @media (max-width: 500px) {
      h2 {
        font-size: 24px;
        color: #FFFFFF;
        margin-bottom: 24px;

        span {
          color: #E42E30;
        }
    }
  `}
`;

export const WrapperLocalizations = styled.div`
  display: grid;
  gap: 18px 120px;
  grid-template-areas:
    "div1 div2"
    "div3 div4"
    "div5 div5";

  justify-content: center;

  > :nth-child(1) {
    grid-area: div1;
  }

  > :nth-child(2) {
    grid-area: div2;
  }

  > :nth-child(3) {
    grid-area: div3;
  }

  > :nth-child(4) {
    grid-area: div4;
  }
  > :nth-child(5) {
    grid-area: div5;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "div1"
      "div2"
      "div3"
      "div4"
      "div5";
    gap: 24px 0;
  }
`;

export const CardLocalization = styled.div`
  margin-bottom: 24px;
  cursor: pointer;

  > img {
    display: block;
    margin: auto;
    margin-bottom: 16px;
    width: 34px;
  }

  > span {
    border: 2px solid #e42e30;
    display: block;
    padding: 22px 0;
    width: 285px;
    margin: 0 auto;
    border-radius: 2px 40px;

    > p {
      color: #fff;
      text-align: center;
      font-family: "Roboto";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  @media (max-width: 500px) {
    > img {
      width: 24px;
    }

    span {
      width: 228px;

      > p {
        font-size: 16px;
      }
    }
  }
`;

export const Contact = styled.section`
  ${({ theme: { space } }) => css`
    width: 100%;
    /* max-width: ${space.large}; */
    margin: 0 auto;
    padding: 120px 0;
    background-color: #e42e30;

    @media (max-width: 500px) {
      padding: 32px 20px;
    }
  `}
`;
export const ContactContent = styled.section`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.medio};
    margin: 0 auto;

    h2 {
      color: #1c1c1c;
      font-family: "Roboto";
      font-size: 72px;
      font-style: normal;
      font-weight: 900;
      line-height: 80px;
      text-transform: uppercase;
      margin-bottom: 80px;
      text-align: center;

      span {
        color: #fff;
      }
    }

    @media (max-width: 500px) {
      h2 {
        font-size: 20px;
        margin-bottom: 24px;
      }
    }
  `}
`;

export const WrapperContacts = styled.div`
  display: grid;
  gap: 50px 130px;
  align-items: end;
  margin-bottom: 50px;

  grid-template-areas: "endereco1 endereco2" "contato1 contato2";

  > :nth-child(1) {
    grid-area: endereco1;
  }

  > :nth-child(2) {
    grid-area: contato1;
  }

  > :nth-child(3) {
    grid-area: endereco2;
  }

  > :nth-child(4) {
    grid-area: contato2;
  }

  > :nth-child(2),
  > :nth-child(4) {
    > h4 {
      font-family: Roboto;
      font-size: 48px;
      font-weight: 700;
      line-height: 60px;
      text-align: center;
      color: #1c1c1c;
      display: flex;
      gap: 0 10px;
    }
  }

  > :nth-child(1),
  > :nth-child(3) {
    padding-left: 80px;
  }

  p {
    color: #fff;
    font-family: "Roboto";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px;
    text-transform: uppercase;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-areas: "endereco1" "contato1" "endereco2" "contato2";
    justify-content: center;
    gap: 32px 0;

    p {
      font-size: 16px;
      text-align: center;
      line-height: 32px;
    }

    > :nth-child(2),
    > :nth-child(4) {
      > h4 {
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        justify-content: center;
        color: #fff;

        > img {
          width: 30px;
        }
      }
    }
  }
`;

export const ContactsLeftSide = styled.div`
  width: fit-content;
`;

export const ContactsRightSide = styled.div`
  width: fit-content;
`;

export const WrapperContactsPhones = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 110px;

  h4 {
    color: #1c1c1c;
    text-align: center;
    font-family: "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    display: flex;
    align-items: center;
    gap: 0 12px;
  }
`;
