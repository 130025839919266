import styled, { css } from "styled-components";

export const Footer = styled.footer`
  ${({ theme: { space } }) => css`
    /* max-width: ${space.large}; */
    width: 100%;
    margin: 0 auto;
    background-color: #111;
  `}
`;

export const FooterMainContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;
    padding: 80px 0;
  `}
`;

export const Logo = styled.img`
  margin: 0 auto;
  margin-bottom: 50px;
  width: 373px;
  display: block;

  @media (max-width: 500px) {
    width: 200px;
    margin-bottom: 40px;
  }
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  gap: 40px 130px;
  align-items: center;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-areas:
    "checklist email localizacao"
    "checklist rede localizacao";

  h2 {
    color: #eee;
    text-align: center;
    font-family: "Roboto";
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    margin-bottom: 28px;
    gap: 0 12px;
  }

  > :nth-child(1) {
    grid-area: checklist;
  }

  > :nth-child(2) {
    grid-area: email;

    p {
      font-family: Roboto;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: #eeeeee;
      display: flex;
      gap: 0 10px;
      align-items: center;
    }
  }

  > :nth-child(3) {
    grid-area: rede;

    > div {
      display: flex;
      gap: 0 30px;
      align-items: center;
    }
  }

  > :nth-child(4) {
    grid-area: localizacao;

    > p {
      font-family: Roboto;
      font-size: 18px;
      color: #eeeeee;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin-bottom: 12px;
    }
  }

  @media (max-width: 500px) {
    grid: 1fr;
    grid-template-areas: "checklist" "email" "localizacao" "rede";
    justify-content: center;

    gap: 32px 0;

    h2 {
      font-size: 20px;
      line-height: 32px;
      display: block;
    }

    > :nth-child(3) {
      > div {
        justify-content: center;
      }
    }

    > :nth-child(4) {
      p {
        text-align: center;
      }
    }
  }
`;

export const RedContent = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    /* max-width: ${space.large}; */
    margin: 0 auto;
    background: #e42e30;
    height: 70px;

    @media (max-width: 500px) {
      height: 116px;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.medio};
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    div.wrapperButtons {
      display: flex;
      gap: 0 6px;
      align-items: center;
    }

    button {
      all: unset;
      cursor: pointer;
    }

    p,
    button {
      color: #feffff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    @media (max-width: 500px) {
      justify-content: center;
      gap: 20px 0;
      flex-direction: column;
    }
  `}
`;

export const LinkDownDoc = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 8px;

  span {
    color: #eee;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    text-decoration-line: underline;
  }
`;
