import styled, { css } from "styled-components";

export const ButtonLogin = styled.button<{ $disabled?: boolean }>`
  ${({ $disabled }) => css`
    cursor: ${$disabled ? "not-allowed" : "pointer"};

    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    min-width: 210px;
    max-width: 210px;
    border-radius: 2px 40px;
    background: linear-gradient(180deg, #2d2d2d 0%, #111 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 22px 40px;
    color: #fff;
    text-align: center;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;

    height: 58px;

    &[data-variant-red-rectangle="true"] {
      background: linear-gradient(180deg, #f53e49 0%, #e42e30 100%);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    &[data-variant-ghost="true"] {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-align: center;
      background: transparent;
      color: #717171;
      padding: 10px;
      box-shadow: none;
      min-width: fit-content;
    }

    &[data-variant-red-circle="true"] {
      background: linear-gradient(180deg, #ff4a55 0%, #e42e30 100%);
      box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 50%;
      height: 96px;
      width: 96px;
      font-size: 16px;
      font-weight: 500;
      padding: 0;
      min-width: max-content;
    }

    &[data-variant-dark-circle="true"] {
      background: linear-gradient(180deg, #353535 0%, #1c1c1c 100%);
      box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 50%;
      height: 96px;
      width: 96px;
      font-size: 16px;
      font-weight: 500;
      padding: 0;
      min-width: max-content;
    }

    @media (max-width: 500px) {
      &[data-variant-red-rectangle="true"] {
        min-width: 200px;
        font-size: 16px;
      }
    }
  `}
`;
