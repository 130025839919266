import React from "react";
import * as S from "./styles";
import { LayoutTemplate } from "../LayoutTemplate";
import { Footer } from "../../Molecules/Footer";

export const OfflineTemplate = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Logo src={"/assets/imgs/logo-default.svg"} alt="logo" />
        <S.AlertIcon
          src="/assets/imgs/alert-circle.svg"
          alt="icone de alerta"
        />
        <S.Title>em manutenção</S.Title>
        <S.Text>
          Direcione-se até a <span>ECV</span> ou <span>CIRETRAN</span> mais
          próxima para realização do seu serviço.
        </S.Text>

        <S.Contact>
          <h2>
            Contatos<span>.</span>
          </h2>
          <S.ContactContent>
            <S.ContactItem>
              <p>SANTA INÊS / MA</p>
              <p>CHAPADINHA / MA</p>
              <p>sÃO JOÃO DOS PATOS / MA</p>

              <h4>
                <img src="/assets/imgs/phone-dark.svg" alt="icone telefone" />
                (98) 98922-4823
              </h4>
            </S.ContactItem>
            <S.ContactItem>
              <p>são josé de ribamar / MA</p>
              <h4>
                <img src="/assets/imgs/phone-dark.svg" alt="icone telefone" />
                (98) 99991-5907
              </h4>
            </S.ContactItem>
          </S.ContactContent>
        </S.Contact>
      </S.Content>
      <Footer />
    </S.Container>
  );
};
