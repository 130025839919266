import React, { useEffect } from "react";
import * as S from "./styles";
import { MainMenu } from "../../Molecules/MainMenu";
import { Footer } from "../../Molecules/Footer";
import { useLocation } from "react-router-dom";

type LayoutTemplateProps = {
  children: React.ReactNode;
};

export const LayoutTemplate = ({ children }: LayoutTemplateProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <S.Container>
      <MainMenu />
      {children}
      <Footer />
    </S.Container>
  );
};
