import React from "react";
import ReactDOM from "react-dom/client";

import { GlobalStyles } from "./Global/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { Theme } from "./Global/Theme";
import { Home } from "./components/Pages/Home";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ContextProvider } from "./context/Context";
import { SchedulingOptions } from "./components/Pages/SchedulingOptions";
import { PageTemplate } from "./components/Templates/PageTemplate";
import { SchedulingTemplate } from "./components/Templates/SchedulingTemplate";
import { Offline } from "./components/Pages/Offline";
import { Services } from "./components/Pages/Services";
import { License } from "./components/Pages/License";
import { Survey } from "./components/Pages/Survey";
import { LoginRegister } from "./components/Pages/LoginRegister";
import { Register } from "./components/Pages/Register";
import { Login } from "./components/Pages/Login";
import { Vehicle } from "./components/Pages/Vehicle";
import { AddressRegister } from "./components/Pages/AddressRegister";
import { Payment } from "./components/Pages/Payment";
import { Pix } from "./components/Pages/Pix";
import { Ticket } from "./components/Pages/Ticket";
import { MySchedules } from "./components/Pages/MySchedules";
import { SchedulesDetail } from "./components/Pages/SchedulesDetail";

import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./components/Atoms/ProtectedRoute";
import { ProtectedClientRoute } from "./components/Atoms/ProtectedClientRoute";
import { TermsOfUse } from "./components/Pages/TermsOfUse/TermsOfUse";
import { PrivacyPolicy } from "./components/Pages/PrivacyPolicy";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const RedirectToLoginCadastro: React.FC = () => {
  window.location.replace(
    "https://mapa.tokyovistorias.com.br/agendamento/login-cadastro"
  );
  return null;
};
const RedirectToAgendamento: React.FC = () => {
  window.location.replace("https://mapa.tokyovistorias.com.br/agendamento");
  return null;
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <ToastContainer autoClose={2000} />
      <GlobalStyles />
      <ContextProvider>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<Navigate to={"/offline"} />} /> */}
            <Route path="/" element={<Home />} />

            <Route path="offline" element={<Offline />} />
            <Route path="politica-de-privacidade" element={<PrivacyPolicy />} />
            <Route path="termos-de-uso" element={<TermsOfUse />} />
            <Route
              path="login-cadastro"
              element={<RedirectToLoginCadastro />}
            />
            <Route path="agendamento" element={<RedirectToAgendamento />} />
          </Routes>
        </BrowserRouter>
      </ContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
