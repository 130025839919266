import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    margin: 0 auto;
    background-color: #e42e30;
  `}
`;

export const Content = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.medio};
    margin: 0 auto;
    padding: 120px 0;

    @media (max-width: 500px) {
      padding: 80px 20px 40px;
    }
  `}
`;

export const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-family: "Roboto";
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: 80px;
  text-transform: uppercase;
  margin: 32px 0;

  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const AlertIcon = styled.img`
  display: block;
  margin: 0 auto;

  @media (max-width: 500px) {
    width: 50px;
  }
`;

export const Text = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Roboto";
  font-size: 24px;
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  max-width: 548px;

  span {
    color: #fff;
    font-style: normal;
    font-weight: 800;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 20px;
    max-width: 265px;
  }
`;

export const Contact = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 50px;
  align-items: center;
  margin-top: 80px;

  > h2 {
    color: #1c1c1c;
    font-size: 72px;
    font-style: normal;
    font-weight: 900;
    line-height: 80px;
    text-transform: uppercase;

    span {
      color: #fff;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;

    > h2 {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 32px;
    }
  }
`;

export const Logo = styled.img`
  width: 340px;
  display: block;
  margin: -40px auto 100px;

  @media (max-width: 500px) {
    width: 218px;
  }
`;

export const ContactContent = styled.div``;

export const ContactItem = styled.div`
  margin-bottom: 50px;

  > p {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    text-transform: uppercase;
  }

  > h4 {
    color: #eee;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    display: flex;
    align-items: center;
    gap: 0 16px;
    margin: 16px 0;
  }

  @media (max-width: 500px) {
    p {
      font-size: 16px;
      font-weight: 800;
      line-height: 32px;
      text-align: center;
    }

    h4 {
      font-size: 20px;
      line-height: 32px;

      > img {
        width: 30px;
      }
    }
  }
`;
