import styled, { css } from "styled-components";

export const Container = styled.nav`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  display: flex;
  gap: 0 48px;
  justify-content: center;

  > a {
    height: min-content;
    margin: auto;
  }

  &[data-hidden="true"] {
    display: none;
  }

  &[data-variant-light="true"] {
    a {
      color: #111;
    }
  }

  @media (max-width: 500px) {
    justify-content: space-between;
    gap: 0 0;
    align-items: center;
    padding: 0 20px;

    &[data-variant-light="true"] {
      a {
        color: #fff;
      }
    }
  }
`;

export const Logo = styled.img`
  cursor: pointer;

  @media (max-width: 500px) {
    display: block;
    width: 120px;

    > a {
      max-width: min-content;
      border: 1px solid red;
    }
  }
`;

export const MenuMobileIcon = styled.img`
  display: none;
  @media (max-width: 501px) {
    display: block;
  }
`;

export const Menu = styled.ul`
  border-top: 2px solid #e42e30;
  border-bottom: 2px solid #e42e30;

  display: flex;
  align-items: center;
  gap: 0 53px;
  padding: 0 20px;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const MenuItem = styled.li`
  a {
    color: #feffff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: #e42e30;
    }
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  gap: 0 40px;
  align-items: center;

  a {
    color: #e42e30;
    text-align: center;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
    white-space: nowrap;

    &[data-variant-default="true"] {
      color: #fff;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const MenuMobile = styled.nav`
  position: fixed;
  background-color: #111;
  inset: 0;
  padding: 40px 20px;

  @media (min-width: 501px) {
    display: none;
  }
`;

export const MenuHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const LogoMobile = styled.img`
  width: 120px;
`;

export const MenuMobileWrapper = styled.ul``;

export const MenuMobileItem = styled.li`
  text-align: center;

  & + li {
    margin-top: 20px;
    border-top: 1px solid #fff;
    padding-top: 20px;
  }

  a {
    color: #fff;
    font-size: 18px;
  }
`;
