import React from "react";
import * as S from "./styles";
import { useFooter } from "./useFooter";

export const Footer = () => {
  const { navigate } = useFooter();
  return (
    <S.Footer>
      <S.FooterMainContent>
        <S.Logo src="/assets/imgs/logo.svg" alt="logo marca da tokyo" />
        <S.Grid>
          <div>
            <h2>Confira os itens verificados</h2>
            <S.LinkDownDoc href="/assets/docs/checklist-tokyo.pdf" download>
              <img
                src="/assets/imgs/icon-checklist.svg"
                alt="icone checklist"
              />
              <span>Baixar Checklist</span>
            </S.LinkDownDoc>
          </div>
          <div>
            <h2>Fale conosco</h2>

            <p>
              <img src="/assets/imgs/mail.svg" alt="icone de carta" />{" "}
              contato@tokyovistoria.com
            </p>
          </div>

          <div>
            <h2>Redes Sociais</h2>

            <div>
              <img src="/assets/imgs/facebook.svg" alt="icone do facebook" />
              <img src="/assets/imgs/instagram.svg" alt="icone do instagram" />
              <img src="/assets/imgs/youtube.svg" alt="icone do youtube" />
            </div>
          </div>

          <div>
            <h2>
              <img src="/assets/imgs/pin-map.svg" alt="marcarcao no mapa" />{" "}
              Localização
            </h2>

            <p>Santa Inês/ MA</p>
            <p>Barra do Corda / MA</p>
            <p>Chapadinha / MA</p>
            <p>São José de Ribamar / MA</p>
            <p>São João dos Patos / MA</p>
          </div>
        </S.Grid>
      </S.FooterMainContent>
      <S.RedContent>
        <S.Wrapper>
          <p>
            Direitos Autorais <span>©</span> 2023 TokyoVistoria
          </p>
          <div className="wrapperButtons">
            <button onClick={() => navigate("/termos-de-uso")}>
              Termos de Uso
            </button>
            <img src="/assets/imgs/ponto.svg" alt="ponto" />
            <button onClick={() => navigate("/politica-de-privacidade")}>
              Política de Privacidade
            </button>
          </div>
        </S.Wrapper>
      </S.RedContent>
    </S.Footer>
  );
};
