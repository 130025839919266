import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    /* max-width: ${space.large}; */
    margin: 0 auto;
    background-color: #e42e30;
    padding: 80px 0 0;

    @media (max-width: 500px) {
      padding: 32px 0 0;
    }
  `}
`;
