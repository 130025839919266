import React, { ComponentProps, useEffect, useState } from "react";
import * as S from "./styles";
import { HashLink } from "react-router-hash-link";
import { Button } from "../../Atoms/Button";
import { Link, useLocation } from "react-router-dom";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { RolesEnum } from "../../../enums/roles";
import { cleanStorage } from "../../../utils/cleanStorage";
import { IoClose } from "react-icons/io5";

interface IMainMenuProps extends ComponentProps<"div"> {
  theme?: "light" | "dark";
  logo?: string;
}

export const MainMenu = (props: IMainMenuProps) => {
  const { pathname } = useLocation();
  const [token] = useSessionStorage("@token");

  const isOffline = pathname.includes("/offline");
  const isHome = pathname === "/";

  const [cliente, setCliente] = useSessionStorage("cliente");

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isCliente = !!(
    cliente?.role?.includes(RolesEnum.ROLE_CLIENTE) && token
  );

  const isAdmGerente = cliente?.role?.some(
    (regra) =>
      regra === RolesEnum.ROLE_ADMIN || regra === RolesEnum.ROLE_GERENTE
  );

  function handleHome() {
    if (isHome) return;
    window.open("/", "_self");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function handleLoginLogout() {
    if (token) {
      sessionStorage.removeItem("@token");
      cleanStorage();
      window.open("/", "_self");
      return;
    }

    window.open("/login-cadastro", "_self");
  }

  return (
    <S.Container data-hidden={isOffline} {...props}>
      <S.Logo
        alt="logo marca tokyo vistoria"
        onClick={handleHome}
        src={props.logo ? props.logo : "/assets/imgs/logo.svg"}
      />

      <S.Menu>
        <S.MenuItem>
          <HashLink smooth to={pathname !== "/" ? "/#sobre" : "#sobre"}>
            Quem somos
          </HashLink>
        </S.MenuItem>

        <S.MenuItem>
          <HashLink smooth to={pathname !== "/" ? "/#servicos" : "#servicos"}>
            Serviços
          </HashLink>
        </S.MenuItem>

        <S.MenuItem>
          <HashLink
            smooth
            to={pathname !== "/" ? "/#localizacao" : "#localizacao"}
          >
            Localização
          </HashLink>
        </S.MenuItem>

        <S.MenuItem>
          <HashLink smooth to={pathname !== "/" ? "/#contato" : "#contato"}>
            Contatos
          </HashLink>
        </S.MenuItem>
      </S.Menu>
      <S.WrapperButtons>
        {isCliente && <Link to={"/meus-agendamentos"}>Meus Agendamentos</Link>}
        {isAdmGerente && <Link to={"/meus-agendamentos"}>Agendamentos</Link>}

        <Link to={"/login-registro"}>
          {pathname !== "/" ? (
            <Button data-variant-dark-circle onClick={handleLoginLogout}>
              {" "}
              {token ? "Logout" : "Login"}
            </Button>
          ) : (
            <Button data-variant-red-circle onClick={handleLoginLogout}>
              {" "}
              {token ? "Logout" : "Login"}
            </Button>
          )}
        </Link>
      </S.WrapperButtons>
      <S.MenuMobileIcon
        src="/assets/imgs/menu-mobile.svg"
        alt="icone menu mobile"
        onClick={() => setMenuIsOpen(true)}
      />

      {menuIsOpen && (
        <S.MenuMobile>
          <S.MenuHeaderWrapper>
            <S.LogoMobile alt="icone logo tokyo" src="/assets/imgs/logo.svg" />
            <IoClose
              size={32}
              color="#fff"
              onClick={() => setMenuIsOpen(false)}
            />
          </S.MenuHeaderWrapper>
          <S.MenuMobileWrapper>
            <S.MenuMobileItem>
              <Link
                to={"/login-cadastro"}
                onClick={() => {
                  handleLoginLogout();
                  setMenuIsOpen(false);
                }}
              >
                {token ? "Logout" : "Login"}
              </Link>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                smooth
                to={pathname !== "/" ? "/#sobre" : "#sobre"}
              >
                Quem somos
              </HashLink>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                smooth
                to={pathname !== "/" ? "/#servicos" : "#servicos"}
              >
                Serviços
              </HashLink>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                smooth
                to={pathname !== "/" ? "/#localizacao" : "#localizacao"}
              >
                Localização
              </HashLink>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                smooth
                to={pathname !== "/" ? "/#contato" : "#contato"}
              >
                Contatos
              </HashLink>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                smooth
                to={pathname !== "/" ? "/#sobre" : "#sobre"}
              >
                Quem somos
              </HashLink>
            </S.MenuMobileItem>

            {isCliente && (
              <S.MenuMobileItem>
                <Link
                  onClick={() => setMenuIsOpen(false)}
                  to={"/meus-agendamentos"}
                >
                  Meus Agendamentos
                </Link>
              </S.MenuMobileItem>
            )}
            {isAdmGerente && (
              <S.MenuMobileItem>
                <Link
                  onClick={() => setMenuIsOpen(false)}
                  to={"/meus-agendamentos"}
                >
                  Agendamentos
                </Link>
              </S.MenuMobileItem>
            )}
          </S.MenuMobileWrapper>
        </S.MenuMobile>
      )}
    </S.Container>
  );
};
